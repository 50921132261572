<template>
  <header class="page_header">
    <div class="page_header-content">
      <h1>医療法人社団青空会</h1>
      <h1>青空クリニック</h1>
    </div>
  </header>
</template>

<script>
export default {
  name: "PageHeader",
};
</script>

<style scoped>
.page_header {
  display: flex;
  justify-content: center; /* 横方向の中央揃え */
  align-items: center;    /* 縦方向の中央揃え */
  color: black;
  height: 50vh;          /* ビューポート全体の高さ */
  text-align: center;
  margin-top: 38px;
}

.page_header-content {
  display: flex;
  flex-direction: column; /* 縦方向に配置 */
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.7), 0 0 16px rgba(255, 255, 255, 0.5);
}
</style>
