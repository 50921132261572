<template>
  <div>
    <!-- 背景画像とナビゲーションを含むコンテナ -->
    <div class="image-container">
      <img alt="Aozora image" src="../assets/images/blue_sky01.png" class="background-image" />
      
      <div class="top-main">
        <div class="top-main-inner">
          <div class="txt">
            <p>青空クリニックは、</p>
            <p>地域の皆さまに寄り添いながら、</p>
            <p>健康と安心をお届けします。</p>
          </div>
        </div>
      </div>
      
      <!-- 画像に要素を重ねる -->
      <div class="overlay-image" >
        <PageNavigation />
        <PageHeader />
        <ClinicSchedule />
      </div>
    </div>
    
    <div class="media-txt">
      <p>青空クリニックは、</p>
      <p>地域の皆さまに寄り添いながら、</p>
      <p>健康と安心をお届けします。</p>
    </div>

    <section id="news-list">
      <h2>お知らせ</h2>
      <NewsItem
        v-for="(news, index) in newsList"
        :key="index"
        :news="news"
      />
    </section>

    <section>
      <h2>治療案内</h2>
      <p>当院では内科、皮膚科、泌尿器科など幅広い診療を行っています。</p>
    </section>
    
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import PageNavigation from '../components/PageNavigation.vue';
import ClinicSchedule from '../components/ClinicSchedule.vue';
import NewsItem from '../components/NewsItem.vue';
import PageFooter from '../components/PageFooter.vue';

export default {
  name: 'HomePage',
  components: {
    PageHeader,
    NewsItem,
    PageNavigation,
    ClinicSchedule,
    PageFooter
  },
  data() {
    return {
      newsList: [
        {
          title: 'お知らせ 1',
          message: '青空クリニックの新しい取り組みについてお知らせします。',
          date: '2024-11-10'
        },
        {
          title: 'お知らせ 2',
          message: '診療時間が変更されました。',
          date: '2024-11-05'
        }
      ]
    };
  }
}
</script>

<style scoped>
/* 画像を含むコンテナ */
.image-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* 背景画像 */
.background-image {
  width: 100%;
  height: 100vh;
  background-size: cover; /* 要素全体を覆う */
}

/* ナビゲーションを背景画像上に重ねる */
.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  color: white;
  padding: 0px;
  text-align: center;
}

#news-list {
  margin-top: 20px;
}

#news-list .news-item {
  margin-bottom: 15px;
}

/* `.top-main` スタイル */
.top-main {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  color: white;
}

.media-txt {
  display: none;
}

/* 画面幅が狭い場合に`position: static`にする */
@media (max-width: 768px) {
  .top-main {
    position: static;
    height: auto;
    min-height: 35px;
    padding: 20px;
    color: gray;
  }
  .top-main-inner {
    width: 90%;
  }
  .media-txt {
    display: block;
    text-align: center;
    font-weight: bolder;
    margin: 100px;
  }
}

.top-main-inner {
  position: relative;
  width: 47.2%;
  height: 100%;
}

.txt {
  position: absolute;
  bottom: 63%;
  right: -35%;
  transform: translate(50%, 50%) scale(0.9);
  font-feature-settings: 'palt' 0;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  opacity: 1;
  color: black;
  font-weight: bold;
  font-size: min(2vw, 25px);
}
@media (max-width: 768px) {
  .top-main >>> .txt {
    display: none;
  }
}
.txt p {
  font-size: 0.85em;
}
</style>
