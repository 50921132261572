<template>
  <nav class="nav-menu">
    <!-- ロゴ部分 -->
    <div class="page_navbar-logo">
      <router-link to="/">
        <img src="@/assets/icon/logo.png" alt="Logo" class="logo-image" />
      </router-link>
    </div>
    
    <!-- メニュー部分 -->
    <ul class="menu-list">
      <li class="menu-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="menu-item drop-menu">
        <a>クリニックについて</a>
        <!-- ドロップダウンメニュー -->
        <ul class="drop-menu-list drop-menu-sizing-max">
          <li class="drop-menu-item"><router-link to="/greeting">院長挨拶</router-link></li>
          <li class="drop-menu-item"><router-link to="/notify">お知らせ</router-link></li>
          <li class="drop-menu-item"><router-link to="/access">アクセス</router-link></li>
        </ul>
      </li>
      <li class="menu-item drop-menu">
        <a>治療案内</a>
        <!-- ドロップダウンメニュー -->
        <ul class="drop-menu-list drop-menu-sizing">
          <li class="drop-menu-item"><router-link to="/treatment/gen_internal_medicine">一般内科</router-link></li>
          <li class="drop-menu-item"><router-link to="/treatment/dermatology">皮膚科</router-link></li>
          <li class="drop-menu-item"><router-link to="/treatment/urology">泌尿器科</router-link></li>
          <li class="drop-menu-item"><router-link to="/treatment/exam_immunization">検査・予防接種</router-link></li>
        </ul>
      </li>
      <li class="menu-item drop-menu">
        <a>採用情報</a>
        <!-- ドロップダウンメニュー -->
        <ul class="drop-menu-list drop-menu-sizing">
          <li class="drop-menu-item"><router-link to="/adoption/registered_nurse">看護師</router-link></li>
          <li class="drop-menu-item"><router-link to="/adoption/medical_office_worker">医療事務</router-link></li>
        </ul>
      </li>
    </ul>
    
    <!-- 右側の住所と電話番号 -->
    <div class="page_navbar-info">
      <div class="page_navbar-logo">
        <a href="https://www.instagram.com/aozora_clinic?igsh=MTlua3l4dWt4YWVybw==" target="_blank" rel="noopener noreferrer"><img src="@/assets/icon/insta_icon.png" alt="Logo" class="sns-image" /></a>
      </div>
      <div class="page_navbar-access">
        <p class="address">〒939-8057</p>
        <p class="address">富山県富山市堀141</p>
        <!-- 電話番号部分をリンク化 -->
        <a href="tel:0764204411"><p class="phone">TEL: 076-420-4411</p></a>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'PageNavigation',
}
</script>

<style scoped>
/* ナビゲーションメニュー */
.nav-menu {
  background-color: rgba(243, 241, 241, 0.836); /* メニューの背景色 */
  color: #696969; /* メニューテキストの色 */
  position: fixed;
  display:flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding-top: 10px;
  padding-bottom: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.page_navbar-logo {
  display: flex;
  align-items: center;
}

.logo-image {
  width: 50px;
  height: auto;
  cursor: pointer;
  margin: 0 40px;
}

.sns-image {
  width: 30px;
  height: auto;
  cursor: pointer;
  margin-left: 20px;
}

.menu-list {
  display: flex;
  justify-content: center; /* メニューアイテムを中央揃えに */
  padding: 0;
  margin: 0;
  list-style: none;
}

.menu-item {
  background-color: rgba(243, 241, 241, 0);  /* メニューアイテムの背景色 */
  border-left: 1px solid #40d5ff; /* メニューアイテムの左ボーダー */
  position: relative;
  transition: background-color .8s; /* 背景色の変化をアニメーション化 */
}

.menu-item:last-child {
  border-right: 1px solid #40d5ff; /* 最後のメニューアイテムの右ボーダー */
}

/* メニューアイテムのホバースタイル */
.menu-item:hover .drop-menu-list {
  transform: scaleY(1); /* ドロップダウンメニューの表示 */
}

.drop-menu-sizing-auto {
  width: auto;
} 

.drop-menu-sizing-max {
  width: 100%;
}

.menu-item:hover {
  background-color: #40d5ff; /* メニューアイテムのホバー時の背景色 */
  transition: background-color .8s; /* 背景色の変化をアニメーション化 */
}

.menu-item:hover a {
  color: white; /* メニューアイテムのホバー時の文字色 */
}

.drop-menu-item:hover {
  background-color: #696969; /* メニューアイテムのホバー時の背景色 */
  transition: background-color .8s; /* 背景色の変化をアニメーション化 */
}

.menu-item a {
  align-items: center;
  color:black;
  display: flex;
  height: 50px;
  justify-content: center;
  text-decoration: none; /* リンクの下線を非表示 */
  width: max-content;
  padding: 0 15px;
  font-size: min(1.7vw, 20px);
  font-weight: bolder;
}

/* ドロップダウンメニュー */
.drop-menu {
  position: relative;
}

.drop-menu-list {
  left: 0;
  position: absolute;
  top: 100%;
  transform: scaleY(0); /* ドロップダウンメニューの非表示 */
  transform-origin: center top; /* 変形を適応する基準を設定 */
  transition: transform .3s; /* 表示の変化をアニメーション化 */
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style: none;
}

.drop-menu-item {
  background-color: #fff; /* ドロップダウンメニューの背景色 */
  padding: 10px;
  text-align: center;
  width: auto;
}

.drop-menu-item a {
  color: #696969 !important;
  text-decoration: none;
}

.drop-menu-item:hover a {
  color: #fff !important; /* メニューアイテムのホバー時の文字色 */
}

.page_navbar-info {
  text-align: right;
  display:flex;
}

.page_navbar-access {
  display: block;
  margin: 0 20px;
}

.address,
.phone {
  text-align: left;
  margin: 0;
  color: black;
  margin-top: 10px;
}

/* ナビゲーションバーが固定された状態の余白を確保 */
body {
  padding-top: 60px; /* ナビゲーションバーの高さ分だけ余白を追加 */
}
</style>
