<template>
  <section id="add-news">
    <h2>お知らせを追加</h2>
    <form @submit.prevent="addNews">
      <div>
        <label for="title">タイトル:</label>
        <input v-model="newNews.title" type="text" id="title" placeholder="タイトルを入力" required />
      </div>
      <div>
        <label for="message">メッセージ:</label>
        <textarea v-model="newNews.message" id="message" placeholder="お知らせ内容を入力" required></textarea>
      </div>
      <div>
        <label for="date">日付:</label>
        <input v-model="newNews.date" type="date" id="date" required />
      </div>
      <button type="submit">お知らせを追加</button>
    </form>
  </section>
</template>

<script>
export default {
  name: 'AddNews',
  data() {
    return {
      newNews: {
        title: '',
        message: '',
        date: ''
      }
    };
  },
  methods: {
    addNews() {
      if (this.newNews.title && this.newNews.message && this.newNews.date) {
        // 親コンポーネントに新しいお知らせを送信
        this.$emit('add-news', { ...this.newNews });
        
        // フォームをリセット
        this.newNews.title = '';
        this.newNews.message = '';
        this.newNews.date = '';
      } else {
        alert('すべての項目を入力してください。');
      }
    }
  }
}
</script>

<style scoped>
#add-news {
  margin-top: 30px;
}

#add-news form {
  display: flex;
  flex-direction: column;
}

#add-news input,
#add-news textarea {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 1em;
}

#add-news button {
  padding: 10px;
  font-size: 1.1em;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

#add-news button:hover {
  background-color: #45a049;
}
</style>
