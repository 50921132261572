<template>
  <div class="news-item">
    <div class="title-date">
      <h3>{{ news.title }}</h3>
      <p><small>{{ news.date }}</small></p>
    </div>
    <p>{{ news.message }}</p>
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: {
    news: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.news-item {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.news-item .title-date {
  display: flex;
  gap: 10px; /* タイトルと日付の間にスペースを追加 */
  align-items: center; /* 垂直方向のセンタリング */
  margin-bottom: 5px; /* タイトルと日付の下に少し余白を追加 */
}

.news-item h3 {
  margin: 0;
  font-size: 1.2em;
}

.news-item p {
  margin: 0;
  margin-left: 5px;
}

.news-item p:last-child {
  border-bottom: none; /* 最後のpには線を引かない */
}
</style>
