<template>
  <div>
    <h1>管理者ページ</h1>
    <p>管理者用のダッシュボードです。</p>

    <!-- 新しいお知らせのリスト -->
    <section id="news-list">
      <h2>お知らせ一覧</h2>
      <NewsItem
        v-for="(news, index) in newsList"
        :key="index"
        :news="news"
      />
    </section>

    <!-- お知らせを追加するフォーム（AddNewsコンポーネントを利用） -->
    <AddNews @add-news="addNewsToList" />
  </div>
</template>

<script>
import NewsItem from '../components/NewsItem.vue';
import AddNews from '../components/AddNews.vue';
import '../assets/styles/style.css';

export default {
  name: 'AdminPage',
  components: {
    NewsItem,
    AddNews
  },
  data() {
    return {
      newsList: [
        {
          title: 'お知らせ 1',
          message: '青空クリニックの新しい取り組みについてお知らせします。',
          date: '2024-11-10'
        },
        {
          title: 'お知らせ 2',
          message: '診療時間が変更されました。詳細は公式サイトでご確認ください。',
          date: '2024-11-05'
        }
      ]
    };
  },
  methods: {
    addNewsToList(news) {
      this.newsList.push(news);
    }
  }
}
</script>

<style scoped>
#news-list {
  margin-top: 20px;
}

#news-list h2 {
  font-size: 1.5em;
}

#news-list .news-item {
  margin-bottom: 15px;
}
</style>
