<!-- src/views/GreetingPage.vue -->
<template>
  <div>
    <PageHeader />
    <PageNavigation />
    <h1>院長挨拶</h1>
    <section id="greeting">
      <p>院長の村石です。当院では地域の皆様の健康を守るため、最適な医療サービスを提供いたします。</p>
    </section>

    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import PageNavigation from '../components/PageNavigation.vue'
import PageFooter from '../components/PageFooter.vue'
import '../assets/styles/style.css';

export default {
  name: 'GreetingPage',
  components: {
    PageHeader,
    PageNavigation,
    PageFooter
  }
}
</script>

<style scoped>
h1 {
  color: #2c3e50;
}
</style>
